import imageButtonResponse from "@jspsych/plugin-image-button-response";

import { config } from "../../config/main";

import { getScreenWidth } from "../../lib/utils";
import { buildCameraStartTrial } from "../trials/camera";
import { enterFullscreenTrial } from "../trials/fullscreen";
import { holdUpMarkerTrial } from "../trials/holdUpMarker";
import { initPhotodiodeTrial } from "../trials/initPhotodiode";
import { preloadTrial } from "../trials/preload";

/**
 * Builds the block of trials needed to start and setup the experiment
 * 1) Game assets are preloaded into memory
 * 2) The experiment enters fullscreen
 * 3) The user is prompted to select their gameplay mode
 * 4) Trials used to set up a photodiode and trigger box are displayed (if applicable)
 * 5) Trials used to set up the user's camera are displayed (if applicable)
 * 6) The basic game instructions are displayed
 *
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @returns {Object} A jsPsych (nested) timeline object
 */
export function buildStartProcedure(jsPsych) {
  const procedure = [
    preloadTrial,
    zoomTrial1,
    zoomTrial2,
    keyboardReminderTrial,
    enterFullscreenTrial,
    modeTrial,
  ];

  // Conditionally add the photodiode setup trials
  if (config.USE_PHOTODIODE) {
    procedure.push(holdUpMarkerTrial);
    procedure.push(initPhotodiodeTrial);
  }

  // Conditionally add the camera setup trials
  if (config.USE_CAMERA) {
    procedure.push(buildCameraStartTrial(jsPsych));
  }

  // The game instructions is the last trial of the start procedure
  procedure.push();

  // Return the block as a nested timeline
  return { timeline: procedure };
}

/** Trial for telling the user to reset their zoom level - this is screen 1 */
const zoomTrial1 = {
  type: imageButtonResponse,
  data: { name: "setZoom" },
  stimulus: "assets/images/instructions/before_instructions_setEnvironment_1.svg",
  choices: ["Continue"],
  stimulus_width: () => getScreenWidth(0.75), // Width of the image is 75% of the screen width
};

/** Trial for telling the user to reset their zoom level - this is screen 2 */
const zoomTrial2 = {
  type: imageButtonResponse,
  data: { name: "setZoom" },
  stimulus: "assets/images/instructions/before_instructions_setEnvironment_2.svg",
  choices: ["Continue"],
  stimulus_width: () => getScreenWidth(0.75), // Width of the image is 75% of the screen width
};

/** Trial for telling the user to be mindful about keyboard */
const keyboardReminderTrial = {
  type: imageButtonResponse,
  data: { name: "setKeyboard" },
  stimulus: "assets/images/instructions/before_instructions0_Keyboard.svg",
  choices: ["Continue"],
  stimulus_width: () => getScreenWidth(0.75), // Width of the image is 75% of the screen width
};

/** Trial for telling the user to pick the mode they're playing in */
const modeTrial = {
  type: imageButtonResponse,
  response_ends_trial: true,
  data: { name: "modeSelection" },
  stimulus: "assets/images/instructions/before_instructions1_mode_selection.svg",
  choices: ["Exploring", "Training", "Escaping"],
  stimulus_width: () => getScreenWidth(0.75), // Width of the image is 75% of the screen width
};
