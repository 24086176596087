// TODO @brown-ccv #183: Upgrade to modular SDK instead of compat
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Initialize Firebase and Firestore
firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID ?? "no-firebase",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});
export const db = firebase.firestore();

// Use emulator if on localhost
if (window.location.hostname === "localhost") db.useEmulator("localhost", 8080);

// Get a reference to the Firebase document at
// "/participant_responses/{studyID}/participants/{participantID}"
function getParticipantRef(studyID, participantID) {
  return db.doc(`participant_responses/${studyID}/participants/${participantID}`);
}

// Get a reference to the Firebase document at
// "/participant_responses/{studyID}/participants/{participantID}/data/{startDate}"
export function getExperimentRef(studyID, participantID, startDate) {
  return db.doc(`${getParticipantRef(studyID, participantID).path}/data/${startDate}`);
}

export function getStudyRef(studyID) {
  return db.doc(`registered_studies/${studyID}`);
}

/**
 * Validate the given studyID & participantID combo
 * @param {string} studyID The ID of a given study in Firebase
 * @param {string} participantID The ID of a given participant inside the studyID
 * @returns true if the given studyID & participantID combo is in Firebase, false otherwise
 */
export async function validateParticipant(studyID, participantID) {
  try {
    // .get() will fail on an invalid path
    await getParticipantRef(studyID, participantID).get();
    return true;
  } catch (error) {
    console.error("Unable to validate the experiment:\n", error);
    return false;
  }
}

/**
 * Initialize a new experiment in Firebase
 * Each experiment is its own document in the "data" subcollection. startDate is used as the ID
 * @param {string} studyID The ID of a given study in Firebase
 * @param {string} participantID The ID of a given participant inside the studyID
 * @param {string} startDate The ID of a given participant inside the studyID and participantID
 * @returns true if able to initialize the new experiment, false otherwise
 */
export async function initParticipant(studyID, participantID, startDate) {
  try {
    const experiment = getExperimentRef(studyID, participantID, startDate);
    await experiment.set({
      // TODO @brown-ccv #394: Write GIT SHA here
      // TODO @brown-ccv #394: Store participantID and studyID here, not on each trial
      start_time: startDate,
      // TODO @brown-ccv #394: app_version and app_platform are deprecated
      app_version: window.navigator.appVersion,
      app_platform: window.navigator.platform,
    });
    console.log("Created Firebase document:", studyID, participantID, startDate);
    return true;
  } catch (error) {
    console.error("Unable to initialize the experiment:\n", error);
    return false;
  }
}

/**
 * Adds a JsPsych trial to Firebase.
 * Each trial is its own document in the "trials" subcollection
 * @param {any} data The JsPsych data object from a single trial
 */
export async function addToFirebase(data) {
  const studyID = data.study_id;
  const participantID = data.participant_id;
  const startDate = data.start_date;

  try {
    const experiment = getExperimentRef(studyID, participantID, startDate);
    await experiment.collection("trials").add(data);
  } catch (error) {
    console.error("Unable to add trial:\n", error);
  }
}

/**
 * Fetch Metadata URL associated with the given studyID
 * @param {string} studyID The ID of a given study in Firebase
 * @returns string of metadata URL or null if no metadata url found
 */
export async function fetchMetadataUrl(studyID) {
  try {
    //.get() will fail on invalid path
    const studyDoc = await getStudyRef(studyID).get();
    return studyDoc.data().metadata_url;
  } catch (error) {
    console.error(`Error fetching Metadata URL associated with ${studyID}`, error);
    return null;
  }
}
