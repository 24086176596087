import { config } from "../../config/main";
import { buildCameraEndTrial } from "../trials/camera";
import { exitFullscreenTrial } from "../trials/fullscreen";
import { endCreditsScreen } from "../trials/endCreditsScreen";

/**
 * Builds the procedure needed to end the experiment
 * 1) Trial used to complete the user's camera recording is displayed
 * 2) The experiment exits fullscreen
 *
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {string} studyID The ID of the study that was just logged into
 * @param {string} participantID The ID of the participant that was just logged in
 * @param {string} sessionID The ID of the current session that was just logged in
 * @returns {Object} A jsPsych (nested) timeline object
 */
export function buildEndProcedure(jsPsych, metadata, studyID, participantID, sessionID) {
  const procedure = [];

  // Conditionally add the camera breakdown trials
  if (config.USE_CAMERA) {
    procedure.push(buildCameraEndTrial(jsPsych));
  }

  // Add the other trials needed to end the experiment
  procedure.push(exitFullscreenTrial, endCreditsScreen);

  // Return the block as a nested timeline
  return {
    timeline: procedure,
    on_timeline_finish: () => {
      jsPsych.data.addProperties({ experimentCompleted: true });
      const qualtricsUrl =
        metadata.URL_postGame +
        `?PARTICIPANT_ID=${participantID}&STUDY_ID=${studyID}&SESSION_ID=${sessionID}`;
      window.location = qualtricsUrl;
    },
  };
}
